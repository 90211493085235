document.addEventListener("DOMContentLoaded", function() {

    const menu = document.querySelector('#menuB');
    const smallMenu = document.querySelector('#menu-small');

    menu.addEventListener('click', openMenu)

    function openMenu(e) {
        smallMenu.className = (smallMenu.className === 'open') ? '' : 'open';
        e.stopPropogation();
    }

});